import {
  FETCH_GROUPS,
  ACTIVE_GROUPS

} from '../../constants/ActionTypes'

const INIT_STATE = {
  listGroups:false,
  activeGroups:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GROUPS: {
      return {...state, listGroups: action.payload};
    }
    case ACTIVE_GROUPS: {
      return {...state, activeGroups: action.payload};
    }
    default:
      return state;
  }
}
