// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

export const CALLRAILS_CALLS = 'CALLRAILS_CALLS'

export const WEBLEADS_DATA = 'WEBLEADS_DATA'
export const AFFILIATE_GROUPS = 'AFFILIATE_GROUPS'
export const CHAT_LEADS = 'CHAT_LEADS'
export const CHAT_DETAILS = 'CHAT_DETAILS'
export const FETCH_DETAILS_LOADER = 'FETCH_DETAILS_LOADER'
export const GA_AUDIENCE_OVERVIEW = 'GA_AUDIENCE_OVERVIEW'
export const GA_DIMENSION_METRICS = 'GA_DIMENSION_METRICS'
export const CLEAR_AFFILLIATE_GROUPS = 'CLEAR_AFFILLIATE_GROUPS'
export const DASHBOARD_RECORDS = 'DASHBOARD_RECORDS'

//Potential Leads const
export const FILTER_POTENTIAL_LEADS = 'FILTER_POTENTIAL_LEADS'
export const POTENTIAL_LEAD_DETAILS = 'POTENTIAL_LEAD_DETAILS'
export const FETCH_POTENTIAL_LEADS = 'FETCH_POTENTIAL_LEADS'
export const POTENTIAL_LEADS_STATUS = 'POTENTIAL_LEADS_STATUS'
export const PL_LIST_ENABLE = 'PL_LIST_ENABLE'
export const FETCH_PL_ACTIVITY = 'FETCH_PL_ACTIVITY'
export const CHAT_SUMMARY_LOADER = 'CHAT_SUMMARY_LOADER'
export const FETCH_CHAT_SUMMARY = 'FETCH_CHAT_SUMMARY'

//Groups const
export const FETCH_GROUPS = 'FETCH_GROUPS'
export const ACTIVE_GROUPS = 'ACTIVE_GROUPS'

//User const
export const FETCH_AGENTS = 'FETCH_AGENTS'
export const FETCH_USERS = 'FETCH_USERS'
export const SEARCH_USER = 'SEARCH_USER'
export const USERS_DETAILS = 'USERS_DETAILS'
export const FETCH_JSON = 'FETCH_JSON'
export const FETCH_LEAD_FORM = 'FETCH_LEAD_FORM'
export const FETCH_INTEGRATION_CONFIG = 'FETCH_INTEGRATION_CONFIG'
export const FETCH_AFFILIATE_LIST = 'FETCH_AFFILIATE_LIST'
export const FETCH_USER_ACTIVITY = 'FETCH_USER_ACTIVITY'
export const FETCH_ACTIVITY_DETAILS = 'FETCH_ACTIVITY_DETAILS'
export const FETCH_IP = 'FETCH_IP'
export const FETCH_SNAPENGAGE_MSG = 'FETCH_SNAPENGAGE_MSG'

//MyCallAlerts const
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const FETCH_FAQ = 'FETCH_FAQ'
export const FETCH_TOP_CHATS = 'FETCH_TOP_CHATS'
export const CALL_ALERTS = 'CALL_ALERTS'
export const CALL_DETAILS = 'CALL_DETAILS'
export const CALL_RECORDING = 'CALL_RECORDING'

//Chats const
export const FETCH_CHATS = 'FETCH_CHATS'
export const FETCH_CHATS_DETAILS = 'FETCH_CHATS_DETAILS'
export const FETCH_QA = 'FETCH_QA'
export const UPDATE_CHAT = 'UPDATE_CHAT'
