import React from "react";
import {ConnectedRouter} from "react-router-redux";
import {Provider} from "react-redux";
import {BrowserRouter,Route, Switch} from "react-router-dom";
import CacheBuster from "react-cache-buster";
import {version} from "../package.json";


import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, {history} from "./appRedux/store";
import App from "./containers/App/index";


export const store = configureStore();

const NextApp = () =>
  <Provider store={store}>
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={App}/>
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </CacheBuster>
  </Provider>;


export default NextApp;
