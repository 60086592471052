import {
  FETCH_CHATS,
  FETCH_CHATS_DETAILS,
  FETCH_QA,
  UPDATE_CHAT
} from '../../constants/ActionTypes'

const INIT_STATE = {
  chatList:false,
  chatDetails:[],
  qa:[],
  isUpdate:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CHATS: {
      return {...state, chatList: action.payload};
    }
    case FETCH_CHATS_DETAILS: {
      return {...state, chatDetails: action.payload};
    }
    case FETCH_QA: {
      return {...state, qa: action.payload};
    }
    case UPDATE_CHAT: {
      return {...state, isUpdate: action.payload};
    }
    default:
      return state;
  }
}
