import React from 'react';

const Footer = () =>{
  return(
    <footer className="ant-layout-footer" style={{ boxShadow: '0 1px 6px 0 lightgrey'}}>
      <div className="gx-layout-footer-content" style={{height:'30px'}}></div>
    </footer>
  )
}

export default Footer;

