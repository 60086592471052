import {
  FETCH_AGENTS,
  FETCH_USERS,
  SEARCH_USER,
  USERS_DETAILS,
  FETCH_JSON,
  FETCH_LEAD_FORM,
  FETCH_CUSTOMERS,
  FETCH_INTEGRATION_CONFIG,
  FETCH_AFFILIATE_LIST,
  FETCH_USER_ACTIVITY,
  FETCH_ACTIVITY_DETAILS,
  FETCH_IP, FETCH_SNAPENGAGE_MSG
} from '../../constants/ActionTypes'

const INIT_STATE = {
  agents:[],
  userList:[],
  searchUser:[],
  userDetails:[],
  jsonData: [],
  customers:[],
  integrationConfig:[],
  leadForm:false,
  affiliateList:[],
  activityLogs:[],
  activityDetails:[],
  ipList:[],
  snapEngageMsg:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_AGENTS: {
      return {...state, agents: action.payload};
    }
    case FETCH_USERS: {
      return {...state, userList: action.payload};
    }
    case SEARCH_USER: {
      return {...state, searchUser: action.payload};
    }
    case USERS_DETAILS: {
      return {...state, userDetails: action.payload};
    }
    case FETCH_JSON: {
      return {...state, jsonData: action.payload};
    }
    case FETCH_LEAD_FORM: {
      return {...state, leadForm: action.payload};
    }
    case FETCH_CUSTOMERS: {
      return {...state, customers: action.payload};
    }
    case FETCH_INTEGRATION_CONFIG: {
      return {...state, integrationConfig: action.payload};
    }
    case FETCH_AFFILIATE_LIST: {
      return {...state, affiliateList: action.payload};
    }
    case FETCH_USER_ACTIVITY: {
      return {...state, activityLogs: action.payload};
    }
    case FETCH_ACTIVITY_DETAILS: {
      return {...state, activityDetails: action.payload};
    }
    case FETCH_IP: {
      return {...state, ipList: action.payload};
    }
    case FETCH_SNAPENGAGE_MSG: {
      return {...state, snapEngageMsg: action.payload};
    }
    default:
      return state;
  }
}
