import {
  FETCH_POTENTIAL_LEADS,
  POTENTIAL_LEAD_DETAILS,
  FILTER_POTENTIAL_LEADS,
  FETCH_TOP_CHATS,
  POTENTIAL_LEADS_STATUS,
  PL_LIST_ENABLE,
  FETCH_PL_ACTIVITY,
  FETCH_ACTIVITY_DETAILS,
  CHAT_SUMMARY_LOADER,
  FETCH_CHAT_SUMMARY
} from '../../constants/ActionTypes'

const INIT_STATE = {
  leadsData:[],
  leadDetails:[],
  filterLeads: [],
  topChats:[],
  chatStatus:false,
  plListEnable:true,
  activityLogs:[],
  activityDetails:[],
  summaryLoader:false,
  summary:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_POTENTIAL_LEADS: {
      return {...state, leadsData: action.payload};
    }
    case FETCH_TOP_CHATS: {
      return {...state, topChats: action.payload};
    }
    case POTENTIAL_LEAD_DETAILS: {
      return {...state, leadDetails: action.payload};
    }
    case FILTER_POTENTIAL_LEADS: {
      return {...state, filterLeads: action.payload};
    }
    case POTENTIAL_LEADS_STATUS: {
      return {...state, chatStatus: action.payload};
    }
    case PL_LIST_ENABLE: {
      return {...state, plListEnable: action.payload};
    }
    case FETCH_PL_ACTIVITY: {
      return {...state, activityLogs: action.payload};
    }
    case FETCH_ACTIVITY_DETAILS: {
      return {...state, activityDetails: action.payload};
    }
    case CHAT_SUMMARY_LOADER: {
      return {...state, summaryLoader:action.payload};
    }
    case FETCH_CHAT_SUMMARY: {
      return {...state, summary: action.payload};
    }
    default:
      return state;
  }
}
