import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  AFFILIATE_GROUPS,
  FETCH_DETAILS_LOADER,
  CLEAR_AFFILLIATE_GROUPS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  affiliateGroups:[],
  loaderDetails: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case AFFILIATE_GROUPS: {
      return {...state, affiliateGroups: action.payload};
    }
    case FETCH_DETAILS_LOADER: {
      return {...state, loaderDetails: action.payload};
    }
    case CLEAR_AFFILLIATE_GROUPS: {
      return {...state, affiliateGroups: []};
    }
    default:
      return state;
  }
}
