import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import CallRails from "./CallRails";
import Dashboard from './Dashboard';
import Leads from './Leads';
import Groups from './Groups';
import Users from  './Users';
import PotentialLeads from './PotentialLeads';
import Chats from './Chats'
import MCA from './MCA'

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  commonData: Common,
  callRails:CallRails,
  dashboard: Dashboard,
  Leads: Leads,
  groups: Groups,
  users: Users,
  potentialLeads: PotentialLeads,
  chats:Chats,
  MCA:MCA

});

export default reducers;
