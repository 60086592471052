import {
  CALL_ALERTS,
  CALL_DETAILS,
  FETCH_CUSTOMERS,
  CALL_RECORDING
} from '../../constants/ActionTypes'

const INIT_STATE = {
  callsData:[],
  callDetails:[],
  customers:[],
  callRecording:false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CALL_ALERTS: {
      return {...state, callsData: action.payload};
    }
    case CALL_DETAILS: {
      return {...state, callDetails: action.payload};
    }
    case FETCH_CUSTOMERS: {
      return {...state, customers: action.payload};
    }
    case CALL_RECORDING:{
      return {...state, callRecording: action.payload};
    }
    default:
      return state;
  }
}
