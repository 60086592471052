import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (

  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}call-log`} component={asyncComponent(() => import('./CallRails'))}/>
      <Route path={`${match.url}analytics`} component={asyncComponent(() => import('./GoogleAnalytics'))}/>
      <Route path={`${match.url}analytics-GA4`} component={asyncComponent(() => import('./GoogleAnalytics/GA4'))}/>

      <Route path={`${match.url}supervisor-chat`} component={asyncComponent(() => import('./Supervisor'))}/>
      <Route path={`${match.url}web-leads`} component={asyncComponent(() => import('./WebLeads'))}/>
      <Route path={`${match.url}chat-leads`} component={asyncComponent(() => import('./ChatLeads'))}/>
      <Route path={`${match.url}settings`} component={asyncComponent(() => import('./Settings'))}/>
      <Route path={`${match.url}potential-leads`} component={asyncComponent(() => import('./PotentialLeads'))}/>
      <Route path={`${match.url}potential-leads-details/`} component={asyncComponent(() => import('./PotentialLeads/details'))}/>
      <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))}/>
      <Route path={`${match.url}users-form`} component={asyncComponent(() => import('./Users/form'))}/>
      <Route path={`${match.url}users-edit/`} component={asyncComponent(() => import('./Users/form'))}/>
      <Route path={`${match.url}affiliate`} component={asyncComponent(() => import('./Users/affiliates'))}/>
      <Route path={`${match.url}users-activity`} component={asyncComponent(() => import('./Users/activityLogs'))}/>
      <Route path={`${match.url}pl-activity`} component={asyncComponent(() => import('./PotentialLeads/activityLogs'))}/>
      <Route path={`${match.url}gmb-setting`} component={asyncComponent(() => import('./GMB'))}/>
      <Route path={`${match.url}groups`} component={asyncComponent(() => import('./Groups'))}/>
      <Route path={`${match.url}chats`} component={asyncComponent(() => import('./Chats'))}/>
      <Route path={`${match.url}qa`} component={asyncComponent(() => import('./Users/qa'))}/>
      <Route path={`${match.url}mca`} component={asyncComponent(() => import('./MCA'))}/>
      <Route path={`${match.url}callalerts`} component={asyncComponent(() => import('./MCA/callalerts'))}/>
      <Route path={`${match.url}announcements`} component={asyncComponent(() => import('./Announcements'))}/>
      {/* <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/> */}
    </Switch>
  </div>
);

export default App;
